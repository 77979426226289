import "./index.css"
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {
    closeWorkOrderApi,
    delWorkOrderApi,
    listExamineWorkOrderApi,
} from "../../../../apis/workOrder";
import {dateUtcFormat} from "../../../../utils/TimeUtil";
import {Modal, Badge, Dropdown, Menu, Pagination, Spin} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {success_notice} from "../../../../utils/noticeUtils";
import types, {getTypeLabel, getWorkOrderType} from "../../../../utils/TypeUtil";


let preSearchText = ""
export default function WorkOrderExamine() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [workOrderTableData, setWorkOrderTableData] = useState({
        total: 1,
        rows: [],
    })

    useEffect(()=>{
        onSearch("all", "")
    }, [])


    const onSearch = (type, searchText, page=1, pageSize=null) => {
        preSearchText = searchText;
        setLoading(true);
        listExamineWorkOrderApi({
            type,
            searchText,
            page: page,
            pageSize: pageSize || 10,
        }).then(res=>{
            if(res.code===200){
                setWorkOrderTableData(res.data)
            }
            setLoading(false);
        }).catch(e=>{
            setLoading(false)
        })
    }


    const del = (workOrder)=>{
        Modal.confirm({
            title: ["删除工单"],
            icon: <ExclamationCircleOutlined />,
            content: [`你是否确认删除工单 “${workOrder.title}” `],
            okText: "确认",
            okType: 'danger',
            cancelText: "取消",
            onOk() {
                delWorkOrderApi(workOrder.id).then(res=>{
                    if(res.code===200){
                        success_notice("删除成功!")
                        onSearch("all", "")
                    }
                })
            },
        });
    }

    const close = (workOrder)=>{
        Modal.confirm({
            title: ["关闭工单"],
            icon: <ExclamationCircleOutlined />,
            content: [`你是否确认关闭工单 “${workOrder.title}” `],
            okText: "确认",
            okType: 'danger',
            cancelText: "取消",
            onOk() {
                closeWorkOrderApi(workOrder.id).then(res=>{
                    if(res.code===200){
                        success_notice("关闭成功!")
                        onSearch("all", "")
                    }
                })
            },
        });
    }


    const handleButtonClick = (workOrder) => {
        navigate(`/forum/workOrder/details/${workOrder.id}`)
    };



    return <div className={"work-order-examine"}>
        <Spin spinning={loading}>
        <div className="ticket-container">
            <div className="main-content">
                <div className="ticket-section">
                    <div className="section-header">
                        <h2 className="section-title">待处理工单</h2>
                    </div>
                    <ul className="ticket-list" style={{padding: "0 20px"}}>
                        {workOrderTableData.rows.map(workOrder=> {
                            return <Badge.Ribbon text={getWorkOrderType(workOrder)} color="cyan" key={workOrder.id}> <li style={{padding: "30px 20px"}} className="ticket-item">
                                <div className="ticket-info" >
                                    <div>
                                        <a  className="ticket-title" style={{display:"inline"}}>{workOrder.title}</a> <span style={{color: "#3498db"}}>【{getTypeLabel(types.workOrderStatusType, workOrder.status)}】</span>
                                    </div>

                                    <div className="ticket-meta">
                                        <span>工单号：#{workOrder.id}</span>
                                        <span>创建时间：{dateUtcFormat(workOrder.createAt, "YYYY-MM-DD")}</span>
                                    </div>
                                </div>
                                <div>
                                    <Dropdown.Button overlay={<Menu onClick={(e)=>{
                                        if(e.key === "del")del(workOrder)
                                        if(e.key === "close")close(workOrder)
                                    }}>
                                        <Menu.Item key="close">关闭工单</Menu.Item>
                                        <Menu.Item key="del" >删除工单</Menu.Item>
                                    </Menu>} onClick={()=>{
                                        handleButtonClick(workOrder)
                                    }}>
                                        查看详情
                                    </Dropdown.Button>
                                </div>


                            </li> </Badge.Ribbon>
                        })}

                    </ul>
                    <div className="pagination">
                        <div className="pagination-info" style={{color: "#7F8C8DFF"}}>共 {workOrderTableData.total} 条工单</div>
                        <div className="pagination-controls">
                            <Pagination size="small" current={workOrderTableData?.page || 1}
                                        pageSize={workOrderTableData?.pageSize || 10} total={workOrderTableData?.total}
                                        onChange={(page, pageSize) => {
                                            onSearch("all", preSearchText, page, pageSize)
                                        }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

            </Spin>

    </div>
}



