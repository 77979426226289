


import request from "../utils/request";


/**
 *
 * @param type 支付方式 alipay 支付宝 wxpay微信
 * @param memory 价格
 * @return {*}
 */
export function createOrderApi(type, memory){
    return request({
        url: `/order/create`,
        method: "post",
        params:{
            memory,
            type
        }
    })
}



export function getPendingOrderListApi(){
    return request({
        url: `/order/pending`,
        method: "get",

    })
}


// 获取已支付的订单
export function getPaidOrderListApi(){
    return request({
        url: `/order/paid`,
        method: "get",

    })
}



export function delPendingOrder(orderNo){
    return request({
        url: `/order/pending/${orderNo}`,
        method: "delete",
    })
}

