import {Col, ConfigProvider, Modal, Row, Space} from 'antd';
import React, {useEffect} from 'react';
import {BrowserRouter} from "react-router-dom";
import 'moment/locale/zh-cn';

import './App.css'  

import {Provider} from "react-redux"
import store from "./redux/store"
import {Route, Routes} from "react-router";
import ForumIndex from "./page/forum/Index";
import ForumHeader from "./page/forum/Layout/Header";
import Header from "./page/Layout/Header";
import Index from "./page/Index";
import Download from "./page/Download";
import UserInfo from "./page/forum/UserInfo";
import PostManage from "./page/forum/Post/Manage";
import ModManage from "./page/forum/Mod/Manage";
import PostAdd from "./page/forum/Post/Add";
import Login from "./page/forum/Login";
import {getLoginInfoApi} from "./apis/user";
import {changeUserInfoAction} from "./redux/action/UserAction";
import PostInfo from "./page/forum/Post/Info";
import WorkOrderManage from "./page/forum/WorkOrder/Manage";
import WorkOrderAdd from "./page/forum/WorkOrder/Add";
import WorkOrderInfo from "./page/forum/WorkOrder/Info";
import UserUpdate from "./page/forum/UserInfo/Update";
import Register from "./page/forum/Login/Register";
import CommonProblem from "./page/forum/WorkOrder/CommonProblem";
import ForgotPassword from "./page/forum/Login/ForgotPassword";
import DonationPanel from "./page/forum/Donation/DonationPanel";
import UserAgreementTip from "./page/agreement/UserAgreementTip";
import UserAgreement from "./page/agreement/UserAgreement";
import Notice from "./page/forum/Notice";
import WorkOrderExamine from "./page/forum/WorkOrder/Examine";
import DonationSuccess from "./page/forum/Donation/DonationSuccess";
import {ExclamationCircleOutlined} from "@ant-design/icons";



// 支持replaceAll方法
// eslint-disable-next-line
if(String.prototype.replaceAll == undefined){
    // eslint-disable-next-line
    String.prototype.replaceAll = function (targetStr, newStr) {
        var sourceStr = this.valueOf();

        while (sourceStr.indexOf(targetStr) !== -1) {
            sourceStr = sourceStr.replace(targetStr, newStr);
        }

        return sourceStr;
    };
}


const App = () => {

    useEffect(()=>{
        getLoginInfoApi().then(res=>{
            changeUserInfoAction(res.data)
        })

    }, [])

    return (<div>
            <BrowserRouter >
                <Routes>
                    <Route path={"/forum/*"} element={<ForumApp/>} />
                    <Route path={"/*"} element={<OfficialWebsiteApp/>} />
                </Routes>
            </BrowserRouter>
            <Footer/>
            <UserAgreementTip/>
        </div>
    );
};


function Footer(){
    return (
        <footer className="forum-footer">
            <div className="footer-container">
                <Row gutter={[48, 32]} justify="center" align="top">
                    <Col xs={24} sm={8} md={6} className="footer-col">
                        <Space direction="vertical" size={16} style={{width: '100%'}}>
                            <div className="footer-title">PocketDestiny</div>
                            <Space direction="vertical" size={12} className="footer-links">
                                <a className="footer-link" href="/" target="_blank" rel="noreferrer">PocketDestiny官网</a>
                                <a className="footer-link" href="/forum" target="_blank" rel="noreferrer">PocketDestiny论坛</a>
                                <a className="footer-link" href="/userAgreement" target="_blank" rel="noreferrer">用户协议</a>
                            </Space>
                        </Space>
                    </Col>
                    <Col xs={24} sm={8} md={6} className="footer-col">
                        <Space direction="vertical" size={16} style={{width: '100%'}}>
                            <div className="footer-title">下载</div>
                            <Space direction="vertical" size={12} className="footer-links">
                                <a className="footer-link" href="/download" target="_blank" rel="noreferrer">游戏下载</a>
                            </Space>
                        </Space>
                    </Col>
                    <Col xs={24} sm={8} md={6} className="footer-col">
                        <Space direction="vertical" size={16} style={{width: '100%'}}>
                            <div className="footer-title">关于我们</div>
                            <Space direction="vertical" size={12} className="footer-links">
                                <a className="footer-link" href="https://space.bilibili.com/472379096" target="_blank" rel="noreferrer">哔哩哔哩</a>
                                <a className="footer-link" href="https://afdian.com/a/PocketDestiny" target="_blank" rel="noreferrer">爱发电</a>
                            </Space>
                        </Space>
                    </Col>
                </Row>
            </div>
        </footer>
    );
}


function ForumApp() {

    return <div>
    <ForumHeader/>
        <Routes>
            <Route path={"/"} element={<ForumIndex/>}/>
            <Route path={"/login"} element={<Login/>}/>
            <Route path={"/register"} element={<Register/>} />
            <Route path={"/forgotPassword"} element={<ForgotPassword/>} />
            <Route path={"/userInfo"} element={<UserInfo/>} />
            <Route path={"/user/update"} element={<UserUpdate/>} />
            <Route path={"/notice"} element={<Notice/>} />
            <Route path={"/post"} element={<PostManage/>} />
            <Route path={"/post/add"} element={<PostAdd/>} />
            <Route path={"/post/update/:id"} element={<PostAdd/>} />
            <Route path={"/post/details/:id"} element={<PostInfo/>} />
            <Route path={"/workOrder"} element={<WorkOrderManage/>} />
            <Route path={"/workOrder/examine"} element={<WorkOrderExamine/>} />
            <Route path={"/workOrder/details/:id"} element={<WorkOrderInfo/>} />
            <Route path={"/workOrder/add"} element={<WorkOrderAdd/>} />
            <Route path={"/mod"} element={<ModManage/>} />
            <Route path={"/commonProblem"} element={<CommonProblem/>} />
            <Route path={"/donationPanel"} element={<DonationPanel/>} />
            <Route path={"/donation/success"} element={<DonationSuccess/>} />

        </Routes>
    </div>
}


function OfficialWebsiteApp(){
    return <div>
        <Header/>
        <Routes>
            <Route path={"/"} element={<Index/>} />
            <Route path={"/download"} element={<Download/>} />
            <Route path={"/userAgreement"} element={<UserAgreement/>} />
        </Routes>
    </div>
}

function AppPlus() {
    return (
        <ConfigProvider >
            <Provider store={store}>
                <App />
            </Provider>
        </ConfigProvider>
    )

}

export default AppPlus;
