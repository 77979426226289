import './index.css'
import {Button, Input, Modal, Pagination, Space, Spin, InputNumber, Radio, message, Select} from "antd";
import {useEffect, useState} from "react";
import {delPostFavoritesApi, listFavoritesApi} from "../../../apis/favorites";
import {dateUtcFormat} from "../../../utils/TimeUtil";
import {postStatisticsApi} from "../../../apis/post";
import {connect} from "react-redux";
import {getServerImgPath} from "../../../utils/ImageUtil";
import {DeleteOutlined, ExclamationCircleOutlined, EditOutlined, GiftOutlined,PlusOutlined, AlipayCircleOutlined, WechatOutlined} from "@ant-design/icons";
import {success_notice} from "../../../utils/noticeUtils";
import {useNavigate} from "react-router-dom";
import {createOrderApi, delPendingOrder, getPendingOrderListApi} from "../../../apis/order";
import {exchangePointsApi} from "../../../apis/user";
import {getServerListApi, getCharacterListApi} from "../../../apis/user";

let preSearchText = ""


const sexMap = {
    "male": "男",
    "female": "女",
    "other": "其他",
}
function UserInfo(props) {
    const {userInfo} = props
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [searchText, setSearchText] = useState("")
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [donationAmount, setDonationAmount] = useState(10);
    const [paymentMethod, setPaymentMethod] = useState('wxpay');
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [isExchangeModalVisible, setIsExchangeModalVisible] = useState(false);
    const [characterName, setCharacterName] = useState('');
    const [exchangeLoading, setExchangeLoading] = useState(false);

    const [exchangePoints, setExchangePoints] = useState(userInfo?.donationCoin || 0);
    const [serverList, setServerList] = useState([]);
    const [characterList, setCharacterList] = useState([]);
    const [selectedServer, setSelectedServer] = useState(null);
    const [selectedCharacter, setSelectedCharacter] = useState(null);
    const [loadingCharacters, setLoadingCharacters] = useState(false);

    const [tableData, setTableData] = useState({
        total: 0,
        page: 1,
        pageSize: 10,
        rows: []
    })

    const [data, setData] = useState({
        count: 0,
        view: 0,
        like: 0,
        star: 0,
    })


    useEffect(()=>{
        if(userInfo.id){
            postStatisticsApi(userInfo.id).then(res => {
                if (res.code === 200) {
                    setData(res.data)
                }
            })

            onSearch("")
        }

    }, [userInfo.id])


    const del = (favorites)=>{
        Modal.confirm({
            title: ["删除收藏帖子"],
            icon: <ExclamationCircleOutlined />,
            content: [`你是否确认删除收藏帖子 " ${favorites.title} " `],
            okText: "确认",
            okType: 'danger',
            cancelText: "取消",
            onOk() {
                delPostFavoritesApi(favorites.id).then(res=>{
                    if(res.code===200){
                        success_notice("删除成功!")
                        onSearch(preSearchText)
                    }
                })

            },
        });

    }

    const onSearch = (searchText="", page=1) => {
        preSearchText = searchText;
        setLoading(true);
        listFavoritesApi({
            searchText,
            page: page,
            pageSize: 10,
        }).then(res=>{
            if(res.code===200){
                setTableData(res.data)
            }
            setLoading(false);
        }).catch(e=>{
            setLoading(false)
        })
    }

    const handleDonation = () => {
        navigate("/forum/donationPanel")
    };

    const handleExchange = () => {
        setIsExchangeModalVisible(true);
        fetchServerList();
    };

    const fetchServerList = () => {
        getServerListApi().then(res => {
            if (res.code === 200) {
                setServerList(res.data);
            }
        });
    };

    const fetchCharacterList = (serverId) => {
        if (!serverId) return;
        setLoadingCharacters(true);
        getCharacterListApi(serverId).then(res => {
            if (res.code === 200) {
                setCharacterList(res.data);
            }
        }).finally(() => {
            setLoadingCharacters(false);
        });
    };

    const handleServerChange = (value) => {
        setSelectedServer(value);
        setSelectedCharacter(null);
        setCharacterList([]);
        fetchCharacterList(value);
    };

    const handleCharacterChange = (value) => {
        setSelectedCharacter(value);
    };

    const handleExchangeOk = () => {
        if (!selectedServer) {
            message.error('请选择服务器');
            return;
        }

        if (!selectedCharacter) {
            message.error('请选择角色');
            return;
        }

        if (!exchangePoints || exchangePoints < 1) {
            message.error('请输入有效的兑换点数');
            return;
        }

        if (exchangePoints > (userInfo?.donationCoin || 0)) {
            message.error('兑换点数不能超过可用点数');
            return;
        }

        setExchangeLoading(true);
        exchangePointsApi({
            serverId: selectedServer,
            roleId: selectedCharacter,
            count: exchangePoints
        }).then(res => {
            if (res.code === 200) {
                message.success('兑换成功');
                setIsExchangeModalVisible(false);

                // 刷新当前页面
                window.location.reload();
            } else {
                message.error(res.msg || '兑换失败，请重试');
            }
        }).catch(err => {
            message.error('兑换失败，请重试');
        }).finally(() => {
            setExchangeLoading(false);
        });
    };

    const handleExchangeCancel = () => {
        setIsExchangeModalVisible(false);
        setSelectedServer(null);
        setSelectedCharacter(null);
        setCharacterList([]);
        setExchangePoints(0);
    };

    return <div className="profile-container">
        <div className="profile-main">
            <div className="profile-header">
                <div className="profile-avatar-container">
                    <img src={getServerImgPath(userInfo?.headUrl)} alt="用户头像" className="profile-avatar"/>
                    <div className="profile-tags">
                        {
                            userInfo?.tags && <>
                                {userInfo.tags.split(",").map(tag => {
                                    return <span className="profile-tag">{tag}</span>
                                })}
                            </>
                        }
                    </div>
                </div>
                <div className="profile-info">
                    <h1>{userInfo?.nickname}</h1>
                    <div className="profile-details">
                        <p>性别：{sexMap[userInfo?.sex || "other"] || sexMap["other"]} </p>
                        <p>加入时间：{dateUtcFormat(userInfo?.createAt, "YYYY-MM-DD")}</p>

                        <Space>
                            <p className="donation-info" style={{marginTop: 5}}>
                                <span>捐赠点：{userInfo?.donationCoin}</span>
                            </p>

                            <Button
                                type="default"
                                icon={<PlusOutlined/>}
                                onClick={handleDonation}
                            >捐赠</Button>
                            <Button
                                type="default"
                                icon={<GiftOutlined/>}
                                onClick={handleExchange}
                            >兑换</Button>
                        </Space>
                    </div>
                    <div className="profile-bio">
                        {userInfo.profile}
                    </div>
                </div>
            </div>

            <a href="/forum/user/update">
                <button className="edit-profile-btn">
                    <EditOutlined/>
                    编辑资料
                </button>
            </a>

            <div className="profile-stats">
                <div className="stat-item">
                    <div className="stat-value">{data.count}</div>
                    <div className="stat-label">发帖数</div>
                </div>
                <div className="stat-item">
                    <div className="stat-value">{data.view}</div>
                    <div className="stat-label">浏览量</div>
                </div>
                <div className="stat-item">
                    <div className="stat-value">{data.like}</div>
                    <div className="stat-label">获赞数</div>
                </div>
                <div className="stat-item">
                    <div className="stat-value">{data.star}</div>
                    <div className="stat-label">收藏数</div>
                </div>


            </div>

            <div className="profile-section">
                <h2>我的收藏</h2>

                <Spin spinning={loading}>
                    <div style={{marginBottom: 8}}>
                        <Input.Search value={searchText} onChange={(val) => {
                            setSearchText(val.target.value)
                        }} onSearch={() => {
                            onSearch(searchText)
                        }} placeholder="搜索帖子..."/>
                    </div>
                    <ul className="favorite-posts">
                        {tableData.rows.map(favorites => {
                            return <li className="favorite-item" key={favorites.id}>
                                <div className="favorite-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path d="M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2z"/>
                                    </svg>
                                </div>
                                <a className="favorite-content" onClick={() => {
                                    navigate(`/forum/post/details/${favorites.postId}`)
                                }}>

                                    <div className="favorite-title">{favorites.title}</div>

                                    <div className="favorite-info">作者：{favorites.userInfo?.nickname} |
                                        收藏时间：{dateUtcFormat(favorites.createAt)}</div>
                                </a>
                                <div>
                                    <Button icon={<DeleteOutlined/>} shape="circle" onClick={() => {
                                        del(favorites)
                                    }}></Button>
                                </div>
                            </li>
                        })}

                    </ul>
                    <div className="pagination">
                        <div className="pagination-info" style={{color: "#7F8C8DFF"}}>共 {tableData.total} 条收藏</div>
                        <div className="pagination-controls">
                            <Pagination size="small" current={tableData?.page || 1}
                                        pageSize={tableData?.pageSize || 10} total={tableData?.total}
                                        onChange={(page) => {
                                            onSearch(preSearchText, page)
                                        }}
                            />
                        </div>
                    </div>
                </Spin>
            </div>
        </div>


        <Modal
            title="兑换捐赠点"
            visible={isExchangeModalVisible}
            onOk={handleExchangeOk}
            onCancel={handleExchangeCancel}
            okText="确认兑换"
            cancelText="取消"
            confirmLoading={exchangeLoading}
            maskClosable={false}
        >
            <div className="exchange-container">
                <div className="exchange-info">
                    <p>当前可兑换：<span className="points">{userInfo?.donationCoin || 0}</span> 点</p>
                    <p className="tip">兑换后的点数将添加到游戏角色中</p>
                </div>
                <div className="exchange-input">
                    <div style={{ marginBottom: 16 }}>
                        <div className="input-label">选择服务器</div>
                        <Select
                            placeholder="请选择服务器"
                            value={selectedServer}
                            onChange={handleServerChange}
                            style={{ width: '100%' }}
                        >
                            {serverList.map(server => (
                                <Select.Option key={server.id} value={server.id}>
                                    {server.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                    <div style={{ marginBottom: 16 }}>
                        <div className="input-label">选择角色</div>
                        <Select
                            placeholder={selectedServer ? (loadingCharacters ? "加载中..." : "请选择角色") : "请先选择服务器"}
                            value={selectedCharacter}
                            onChange={handleCharacterChange}
                            style={{ width: '100%' }}
                            disabled={!selectedServer || loadingCharacters}
                            loading={loadingCharacters}
                        >
                            {characterList.map(character => (
                                <Select.Option key={character.id} value={character.id}>
                                    {character.nickname}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                    <div>
                        <div className="input-label">兑换点数</div>
                        <InputNumber
                            placeholder="请输入兑换点数"
                            min={1}
                            max={userInfo?.donationCoin || 0}
                            precision={0}
                            value={exchangePoints}
                            onChange={value => setExchangePoints(value)}
                            style={{ width: '100%' }}
                            disabled={!selectedCharacter}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    </div>

}

const mapStateToProps = (state) => {
    return {
        userInfo: state.systemInfo.userInfo,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo)