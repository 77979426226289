import "./index.css"
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {
    closeWorkOrderApi,
    delWorkOrderApi,
    getCommonProblemTopApi,
    listWorkOrderApi,
    workOrderStatisticsApi
} from "../../../../apis/workOrder";
import {dateUtcFormat} from "../../../../utils/TimeUtil";
import {Button, Col, Modal, Row, Badge, Dropdown, Menu, Pagination} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {success_notice} from "../../../../utils/noticeUtils";
import types, { getTypeLabel, getWorkOrderType} from "../../../../utils/TypeUtil";


let preSearchText = ""
export default function WorkOrderManage() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [workOrderTableData, setWorkOrderTableData] = useState({
        total: 1,
        rows: [],
    })



    useEffect(()=>{
        onSearch("all", "")
    }, [])


    const onSearch = (type, searchText, page=1, pageSize=null) => {
        preSearchText = searchText;
        setLoading(true);
        listWorkOrderApi({
            type,
            searchText,
            page: page,
            pageSize: pageSize || 10,
        }).then(res=>{
            if(res.code===200){
                setWorkOrderTableData(res.data)
            }
            setLoading(false);
        }).catch(e=>{
            setLoading(false)
        })
    }

    const del = (workOrder)=>{
        Modal.confirm({
            title: ["删除工单"],
            icon: <ExclamationCircleOutlined />,
            content: [`你是否确认删除工单 “${workOrder.title}” `],
            okText: "确认",
            okType: 'danger',
            cancelText: "取消",
            onOk() {
                delWorkOrderApi(workOrder.id).then(res=>{
                    if(res.code===200){
                        success_notice("删除成功!")
                        onSearch("all", "")
                    }
                })
            },
        });
    }

    const close = (workOrder)=>{
        Modal.confirm({
            title: ["关闭工单"],
            icon: <ExclamationCircleOutlined />,
            content: [`你是否确认关闭工单 “${workOrder.title}” `],
            okText: "确认",
            okType: 'danger',
            cancelText: "取消",
            onOk() {
                closeWorkOrderApi(workOrder.id).then(res=>{
                    if(res.code===200){
                        success_notice("关闭成功!")
                        onSearch("all", "")
                    }
                })
            },
        });
    }


    const handleButtonClick = (workOrder) => {
        navigate(`/forum/workOrder/details/${workOrder.id}`)
    };



    return <div className={"work-order-manage"}>
        <div className="ticket-container">
            <div className="main-content">
                <div className="ticket-section">
                    <div className="section-header">
                        <h2 className="section-title">我的工单</h2>
                        <a href="/forum/workOrder/add" className="create-ticket-btn">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round">
                                <line x1="12" y1="5" x2="12" y2="19"></line>
                                <line x1="5" y1="12" x2="19" y2="12"></line>
                            </svg>
                            新建工单
                        </a>
                    </div>
                    <ul className="ticket-list" style={{padding: "0 20px"}}>
                        {workOrderTableData.rows.map(workOrder=> {
                            return <Badge.Ribbon text={getWorkOrderType(workOrder)} color="cyan" key={workOrder.id}> <li style={{padding: "30px 20px"}} className="ticket-item">
                                <div className="ticket-info" >
                                    <div>
                                        <a  className="ticket-title" style={{display:"inline"}}>{workOrder.title}</a> <span style={{color: "#3498db"}}>【{getTypeLabel(types.workOrderStatusType, workOrder.status)}】</span>
                                    </div>

                                    <div className="ticket-meta">
                                        <span>工单号：#{workOrder.id}</span>
                                        <span>创建时间：{dateUtcFormat(workOrder.createAt, "YYYY-MM-DD")}</span>
                                    </div>
                                </div>
                                <div>
                                    <Dropdown.Button overlay={<Menu onClick={(e)=>{
                                        if(e.key === "del")del(workOrder)
                                        if(e.key === "close")close(workOrder)
                                    }}>
                                        <Menu.Item key="close">关闭工单</Menu.Item>
                                        <Menu.Item key="del" >删除工单</Menu.Item>
                                    </Menu>} onClick={()=>{
                                        handleButtonClick(workOrder)
                                    }}>
                                        查看详情
                                    </Dropdown.Button>
                                </div>


                            </li> </Badge.Ribbon>
                        })}

                    </ul>
                    <div className="pagination">
                        <div className="pagination-info" style={{color: "#7F8C8DFF"}}>共 {workOrderTableData.total} 条工单</div>
                        <div className="pagination-controls">
                            <Pagination size="small" current={workOrderTableData?.page || 1}
                                        pageSize={workOrderTableData?.pageSize || 10} total={workOrderTableData?.total}
                                        onChange={(page, pageSize) => {
                                            onSearch("all", preSearchText, page, pageSize)
                                        }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="sidebar">
                <WorkOrderStatistics/>
                <CommonProblemTops/>

            </div>
        </div>

        <button className="theme-toggle">🌓</button>

    </div>
}


export function CommonProblemTops() {
    const [data, setData] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        getCommonProblemTopApi().then(res => {
            if (res.code === 200) {
                setData(res.data)
            }
        })
    }, []);


    return <div className="sidebar-widget">
        <Row justify={"space-between"}>
            <Col>
                <h3 className="widget-title">常见问题</h3>
            </Col>
            <Col>
                <a href="#" onClick={() => {
                    navigate("/forum/commonProblem")
                }}>更多</a>
            </Col>
        </Row>

        <ul className="faq-list">
            {data.map(item=> {
                return <li className="faq-item" key={item.id}>
                    <a href={item.url}>
                        <div className="faq-question">{item.title}</div>
                    </a>

                    <div className="faq-answer">{item.shortDesc}</div>
                </li>
            })}
        </ul>
    </div>

}

function WorkOrderStatistics(props) {
    const [data, setData] = useState({})

    useEffect(() => {
        workOrderStatisticsApi().then(res => {
            if (res.code === 200) {
                setData(res.data)
            }
        })
    }, []);


    return <div className="sidebar-widget">
        <h3 className="widget-title">工单统计</h3>
        <div className="ticket-stats">
            <div className="stat-item">
                <div className="stat-value">{data?.all}</div>
                <div className="stat-label">总工单数</div>
            </div>
            <div className="stat-item">
                <div className="stat-value">{data?.open}</div>
                <div className="stat-label">开启工单</div>
            </div>
            <div className="stat-item">
                <div className="stat-value">{data?.processing}</div>
                <div className="stat-label">处理中工单</div>
            </div>
            <div className="stat-item">
                <div className="stat-value">{data?.close}</div>
                <div className="stat-label">已关闭工单</div>
            </div>
        </div>
    </div>

}


