import {useEffect, useState} from "react";
import {listAfdApi, getServerListApi, getCharacterListApi} from "../../../apis/user";
import {Avatar, Button, List, Result, Modal, InputNumber, Space, message, Radio, Select, Spin} from "antd";

import {connect} from "react-redux";
import {
    HeartOutlined,
    GiftOutlined,
    AlipayCircleOutlined,
    WechatOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons';
import './DonationPanel.css';
import {createOrderApi, delPendingOrder, getPendingOrderListApi, getPaidOrderListApi} from "../../../apis/order";
import {dateUtcFormat} from "../../../utils/TimeUtil";
import {exchangePointsApi} from "../../../apis/user";


function DonationPanel(props){
    const {userInfo} = props;

    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [donationData, setDonationData] = useState({})
    const [hasMore, setHasMore] = useState(true)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [donationAmount, setDonationAmount] = useState(10)
    const [paymentMethod, setPaymentMethod] = useState('wxpay');
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [paidOrders, setPaidOrders] = useState([]);
    const [paidOrdersLoading, setPaidOrdersLoading] = useState(false);
    const [isExchangeModalVisible, setIsExchangeModalVisible] = useState(false);
    const [exchangePoints, setExchangePoints] = useState(userInfo?.donationCoin || 0);
    const [serverList, setServerList] = useState([]);
    const [characterList, setCharacterList] = useState([]);
    const [selectedServer, setSelectedServer] = useState(null);
    const [selectedCharacter, setSelectedCharacter] = useState(null);
    const [loadingCharacters, setLoadingCharacters] = useState(false);
    const [exchangeLoading, setExchangeLoading] = useState(false);

    useEffect(() => {
        addData(page);
        loadPaidOrders();
    }, [page]);

    useEffect(() => {
        const handleScroll = () => {
            if (loading || !hasMore) return;
            
            const scrollTop = window.scrollY;
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;
            
            // 当滚动到距离底部100px时加载更多
            if (documentHeight - scrollTop - windowHeight < 100) {
                setPage(prev => prev + 1);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [loading, hasMore]);

    function addData(page){
        setLoading(true)

        listAfdApi({
            page
        }).then(res=>{
            if(res.code === 200){
                setData(prevData => [...prevData, ...res.data.rows])
                setDonationData(res.data)
                // 如果返回的数据少于预期，说明没有更多数据了
                setHasMore(res.data.rows.length > 0)
            }
            setLoading(false)
        }).catch(e=>{
            setLoading(false)
            setHasMore(false)
        })
    }

    const loadPaidOrders = () => {
        setPaidOrdersLoading(true);
        getPaidOrderListApi().then(res => {
            if(res.code === 200) {
                setPaidOrders(res.data || []);
            }
        }).finally(() => {
            setPaidOrdersLoading(false);
        });
    };

    const handleDirectDonation = () => {
        getPendingOrderListApi().then(res=>{
            if(res.code === 200){
                if(res.data.length > 0){
                    let order = res.data[0];
                    // 有一笔订单创建未支付提示跳转
                    Modal.confirm({
                        title: ["待支付订单"],
                        icon: <ExclamationCircleOutlined />,
                        content: `你有一笔金额为${order.memory}未支付，是否继续支付`,
                        okText: "继续支付",
                        okType: 'danger',
                        cancelText: "删除未支付订单",
                        onOk() {
                            window.location.href = order.payUrl;
                        },
                        onCancel(){
                            delPendingOrder(order.orderNo)
                        }
                    });

                }else {
                    // 弹出添加订单弹窗
                    setIsModalVisible(true);
                }
            }
        })


    }

    const handleModalOk = () => {
        if (!donationAmount || donationAmount < 1 || donationAmount > 3000) {
            message.error('请输入有效的捐赠金额');
            return;
        }

        setConfirmLoading(true);
        // 创建订单
        createOrderApi(paymentMethod, donationAmount).then(res=>{
            if(res.code == 200){
                // 跳转支付链接
                window.location.href = res.data.payUrl;
            } else {

            }
        }).catch(err => {
            message.error('创建订单失败，请重试');
        }).finally(() => {
            setConfirmLoading(false);
            setIsModalVisible(false);
        });
    }

    const handleModalCancel = () => {
        setIsModalVisible(false);
    }

    const handleExchange = () => {
        setIsExchangeModalVisible(true);
        fetchServerList();
    };

    const fetchServerList = () => {
        getServerListApi().then(res => {
            if (res.code === 200) {
                setServerList(res.data);
            }
        });
    };

    const fetchCharacterList = (serverId) => {
        if (!serverId) return;
        setLoadingCharacters(true);
        getCharacterListApi(serverId).then(res => {
            if (res.code === 200) {
                setCharacterList(res.data);
            }
        }).finally(() => {
            setLoadingCharacters(false);
        });
    };

    const handleServerChange = (value) => {
        setSelectedServer(value);
        setSelectedCharacter(null);
        setCharacterList([]);
        fetchCharacterList(value);
    };

    const handleCharacterChange = (value) => {
        setSelectedCharacter(value);
    };

    const handleExchangeOk = () => {
        if (!selectedServer) {
            message.error('请选择服务器');
            return;
        }

        if (!selectedCharacter) {
            message.error('请选择角色');
            return;
        }

        if (!exchangePoints || exchangePoints < 1) {
            message.error('请输入有效的兑换点数');
            return;
        }

        if (exchangePoints > (userInfo?.donationCoin || 0)) {
            message.error('兑换点数不能超过可用点数');
            return;
        }

        setExchangeLoading(true);
        exchangePointsApi({
            serverId: selectedServer,
            roleId: selectedCharacter,
            count: exchangePoints
        }).then(res => {
            if (res.code === 200) {
                message.success('兑换成功');
                setIsExchangeModalVisible(false);
                // 刷新当前页面
                window.location.reload();
            } else {
                message.error(res.msg || '兑换失败，请重试');
            }
        }).catch(err => {
            message.error('兑换失败，请重试');
        }).finally(() => {
            setExchangeLoading(false);
        });
    };

    const handleExchangeCancel = () => {
        setIsExchangeModalVisible(false);
        setSelectedServer(null);
        setSelectedCharacter(null);
        setCharacterList([]);
        setExchangePoints(0);
    };

    return <div className="login-page">
        <div className="background"></div>
        <Result
            status="success"
            icon={<img src="/forum/static/images/icon.png" alt="PocketDestiny Logo"
                       className="card-logo"/>}
            title="感谢所有支持PocketDestiny的粉丝"
            subTitle={<div>
                为PocketDestiny贡献一份力量，成为PocketDestiny捐赠者!
                <div className="my-donation-info">
                    <Space>
                        <span>我的捐赠点：</span>
                        <span className="donation-points">{userInfo?.donationCoin || 0}</span>
                        <Button
                            type="default"
                            icon={<GiftOutlined />}
                            onClick={handleExchange}
                            size="small"
                            className="exchange-btn"
                        >
                            兑换
                        </Button>
                    </Space>
                </div>
            </div>}
            extra={[
                <Space size="middle">
                    <a href={"https://afdian.com/a/PocketDestiny?tab=shop"} target="_blank" rel="noopener noreferrer">
                        <Button className="donate-btn">
                            <HeartOutlined />
                            爱发电捐赠
                        </Button>
                    </a>

                    <Button className="donate-btn" onClick={handleDirectDonation} type="primary">
                        <GiftOutlined />
                        直接捐赠
                    </Button>
                </Space>,
                <div className="bg-container">
                    <div className="card">
                        <div>
                            <h2>我的捐赠记录</h2>
                            <Spin spinning={paidOrdersLoading}>
                                <List
                                    className="paid-orders-list"
                                    itemLayout="horizontal"
                                    dataSource={paidOrders}
                                    locale={{
                                        emptyText: '暂无捐赠记录'
                                    }}
                                    renderItem={(order) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                title={
                                                    <Space>
                                                        <span>捐赠金额：</span>
                                                        <span strong className="donation-amount">￥{order.memory}</span>
                                                    </Space>
                                                }
                                                description={
                                                    <div className="order-details">
                                                        <div>{order.orderNo}</div>
                                                        <div>支付时间：{dateUtcFormat(order.completeTime)}</div>
                                                        <div>支付方式：{order.type === 'wxpay' ? '微信支付' : '支付宝'}</div>
                                                    </div>
                                                }
                                            />
                                        </List.Item>
                                    )}
                                />
                            </Spin>
                        </div>
                        <div className="list-container">
                            <h2>爱发电捐赠者</h2>
                            <span className={"ant-result-subtitle"}>
                                该排名不分先后，感谢以下{donationData.total}位捐赠者
                            </span>
                            <List
                                itemLayout="horizontal"
                                dataSource={data}
                                renderItem={(item) => (
                                    <List.Item>
                                        <List.Item.Meta
                                            avatar={<Avatar src={item.avatar}/>}
                                            title={<a>{item.name}</a>}
                                        />
                                    </List.Item>
                                )}
                            />
                            {loading && <div className="loading-indicator">加载中...</div>}
                            {!loading && !hasMore && data.length > 0 && (
                                <div className="loading-indicator">已加载全部</div>
                            )}
                        </div>
                    </div>
                </div>
            ]}
        />
        <Modal
            title="选择捐赠金额"
            visible={isModalVisible}
            onOk={handleModalOk}
            onCancel={handleModalCancel}
            okText="确认捐赠"
            cancelText="取消"
            width={460}
            bodyStyle={{padding: '24px'}}
            confirmLoading={confirmLoading}
            maskClosable={false}
        >
            <div className="donation-container">
                <div className="donation-section">
                    <div className="donation-label">快捷金额</div>
                    <div className="donation-content">
                        <div className="quick-amount-grid">
                            {[10, 50, 100, 200].map(amount => (
                                <Button 
                                    key={amount}
                                    onClick={() => setDonationAmount(amount)}
                                    type={donationAmount === amount ? 'primary' : 'default'}
                                    size="large"
                                >
                                    ￥{amount}
                                </Button>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="donation-section">
                    <div className="donation-label">自定义金额</div>
                    <div className="donation-content">
                        <InputNumber
                            min={1}
                            max={200}
                            value={donationAmount}
                            size="large"
                            precision={0}
                            step={1}
                            formatter={value => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/￥\s?|(,*)/g, '')}
                            onChange={value => setDonationAmount(value)}
                        />
                    </div>
                </div>

                <div className="donation-section">
                    <div className="donation-label">支付方式</div>
                    <div className="donation-content">
                        <Radio.Group
                            value={paymentMethod}
                            onChange={e => setPaymentMethod(e.target.value)}
                            size="large"
                        >
                            <div className="payment-buttons">
                                <Radio.Button value="wxpay">
                                    <WechatOutlined style={{color: '#52c41a'}}/>
                                    <span>微信支付</span>
                                </Radio.Button>
                                {/*<Radio.Button value="alipay">*/}
                                {/*    <AlipayCircleOutlined style={{color: '#1890ff'}}/>*/}
                                {/*    <span>支付宝</span>*/}
                                {/*</Radio.Button>*/}
                            </div>
                        </Radio.Group>

                        <div>
                            <span
                                style={{color: "red"}}>请您在捐赠之前确认，您所进行的捐赠行为是基于个人意愿和自愿原则，未受到任何外部压力或不当诱导。捐赠行为一旦完成，不可退回，请您对此表示理解和接受 。</span>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
        <Modal
            title="兑换捐赠点"
            visible={isExchangeModalVisible}
            onOk={handleExchangeOk}
            onCancel={handleExchangeCancel}
            okText="确认兑换"
            cancelText="取消"
            confirmLoading={exchangeLoading}
            maskClosable={false}
        >
            <div className="exchange-container">
                <div className="exchange-info">
                    <p>当前可兑换：<span className="points">{userInfo?.donationCoin || 0}</span> 点</p>
                    <p className="tip">兑换后的点数将添加到游戏角色中</p>
                </div>
                <div className="exchange-input">
                    <div style={{ marginBottom: 16 }}>
                        <div className="input-label">选择服务器</div>
                        <Select
                            placeholder="请选择服务器"
                            value={selectedServer}
                            onChange={handleServerChange}
                            style={{ width: '100%' }}
                        >
                            {serverList.map(server => (
                                <Select.Option key={server.id} value={server.id}>
                                    {server.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                    <div style={{ marginBottom: 16 }}>
                        <div className="input-label">选择角色</div>
                        <Select
                            placeholder={selectedServer ? (loadingCharacters ? "加载中..." : "请选择角色") : "请先选择服务器"}
                            value={selectedCharacter}
                            onChange={handleCharacterChange}
                            style={{ width: '100%' }}
                            disabled={!selectedServer || loadingCharacters}
                            loading={loadingCharacters}
                        >
                            {characterList.map(character => (
                                <Select.Option key={character.id} value={character.id}>
                                    {character.nickname}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                    <div>
                        <div className="input-label">兑换点数</div>
                        <InputNumber
                            placeholder="请输入兑换点数"
                            min={1}
                            max={userInfo?.donationCoin || 0}
                            precision={0}
                            value={exchangePoints}
                            onChange={value => setExchangePoints(value)}
                            style={{ width: '100%' }}
                            disabled={!selectedCharacter}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    </div>
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.systemInfo.userInfo,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DonationPanel)