import React, { useEffect, useState } from 'react';
import { Result, Button, Row, Col, Typography } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { HeartFilled } from '@ant-design/icons';
import './DonationSuccess.css';

const { Text } = Typography;

const DonationSuccess = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [countdown, setCountdown] = useState(10);
    const [orderInfo, setOrderInfo] = useState({
        money: '',
        tradeNo: '',
        orderNo: ''
    });

    useEffect(() => {
        // 解析URL参数
        const searchParams = new URLSearchParams(location.search);
        setOrderInfo({
            money: searchParams.get('money') || '0',
            tradeNo: searchParams.get('trade_no') || '',
            orderNo: searchParams.get('out_trade_no') || ''
        });

        const timer = setInterval(() => {
            setCountdown(prev => {
                if (prev <= 1) {
                    clearInterval(timer);
                    navigate('/forum/donationPanel');
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [navigate, location]);

    return (
        <div className="donation-success-container">
            <Result
                icon={<HeartFilled className="success-icon" />}
                status="success"
                title="捐赠成功！"
                subTitle={
                    <div className="success-subtitle">
                        <div className="donation-amount">
                            <Text>捐赠金额：</Text>
                            <Text strong>￥{orderInfo.money}</Text>
                        </div>
                        <div className="order-info">
                            <Text type="secondary">订单号：{orderInfo.orderNo}</Text>
                        </div>
                        <p>感谢您的支持，您的捐赠将帮助我们做得更好！</p>
                        <p className="countdown">{countdown} 秒后自动跳转到首页</p>
                    </div>
                }
                extra={<Row justify='center'>
                    <Col>
                        <Button 
                            type="primary" 
                            onClick={() => navigate('/forum/donationPanel')}
                            className="return-btn"
                        >
                            立即返回首页
                        </Button>
                    </Col>
                </Row>}
            />
        </div>
    );
};

export default DonationSuccess; 